import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import API from "./service/Api";
import useScript from "./utils/ScriptLoder";
import TagManager from "react-gtm-module";
import MainForm from "./component/forms/MainForm";

const defaultData = {
  website_id: "44",
  sub: "",
  domain_id: "1",
  domain_name: "whiterockcash.com",
  server_id: "1",
  logo_img: "logo.png",
  login_img: "login_img.png",
  favicon_img: "favicon.png",
  min_amount: "1000",
  max_amount: "15000",
  banner_img: "banner.png",
  section1_img: "img1.png,img2.png",
  section2_img: "",
  section3_img: "",
  section4_img: "",
  primary_color: "#4080C1",
  secondary_color: "#000000",
  form_id: "1",
  form_url: "https://useasycash.com/formcdn/version1.2.1/?c=Home",
  ga_script: "",
  push_script: "",
  ola_script: "",
  customization: {
    headerSection: {
      section: "Header",
      content: {
        ctaText: "Request Now",
      },
      style: {
        backgroundColor: "#1B1B1E",
        headerBg: "#1B1B1E",
        mobileNavBg: "#f9fffb",
        linkColor: "#FCFCFC",
        linkHoverColor: "#191C1A",
        ctaBg: "#2fc390",
        ctaTextColor: "#ffffff",
        ctaBgBorder: "#2fc390",
        ctaHoverBg: "#9cc983",
        ctaHoverTextColor: "#ffffff",
      },
    },
    bannerSection: {
      section: "Banner",
      content: {
        headingText:
          "Loans Upto $35,000 <span style='color:#2fc390'>For Any Reason</span>",
        subheadingText:
          "You may have loan options waiting. Get started by submitting a loan request.",
        ctaText: "Get Started",
        label1: "Loan Amount",
        label2: "Email Address",
      },
      style: {
        themeColor2: "#2fc390",
        backgroundColor: "#F9FFFB",
        headertextColor: "#FCFCFC",
        paratextColor: "#191C1ACC",
        termsTextColor: "#737780",
        fieldHoverBorder: "#2fc390",
        fieldErrorBorder: "#ff2b2b",
        discLinkColor: "#1B1B1E",
        ctaBg: "#2fc390",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#9cc983",
        ctaHoverTextColor: "#ffffff",
        fontFamily: "Intel",
        form_Header_Bg: "#FFE3EA",
        form_Dot_Bg: "#DF4169",
      },
    },
    howitworks: {
      section: "How it Works",
      content: {
        headingText: "How it works",
        subheadingText: "Simply 3 steps to finalize the process",
        imageheading1: "You submit information",
        imageDescription1: "Fill our quick and simple loan form",
        imageheading2: "Fast Loan Decision",
        imageDescription2:
          "Lenders are quick to respond and, if approved, will reach out to you as soon as they receive your request.",
        imageheading3: "Check Your Account",
        imageDescription3:
          "If your request is approved by the lender, funds may be directly deposited into your account as soon as the next business day.",
        ctaText: "Get Started ",
      },
      style: {
        headingColor: "#0c3d6f",
        subHeadColor: "#292929",
        imageheadingColor: "#242424",
        imageparaColor: "#292929",
      },
    },
    creditScoreSection: {
      section: "Credit Score",
      content: {
        titleText: "A Loan with any Credit Score",
        icon1bold: "Fast Loan <span class='color:#2fc390'>Decision</span>",
        icon2bold:
          "Quick, Simple & <span class='color:#2fc390'>Secure Process</span>",
        icon3bold:
          "See Money in<span class='color:#2fc390'>Your Bank Account</span>",
        mainPara:
          "Our long list of lenders who aren't rigid when it comes to credit history/score. By requesting for a loan via quickloanpros, you might get an offer of debt-consolidation depending on the discretion of the lender. However, we don't guarantee you of acceptance of bad credit as it's wholly and solely on the will of the lender.",
        disclosurePara:
          "Disclosure: Some of our lenders may run credit/background checks via methods of their choice.",
        CTAbuttonText: "Request Now why",
      },
      style: {
        mainHeadingColor: "#2fc390",
        boldTextColor: "#292929",
        mainPraraColor: "#fcfcfc",
        disclosPara: "##0a346d",
        ctaBg: "#2fc390",
        ctaTextColor: "#fff",
        ctaHoverBg: "#9cc983",
        ctaHoverTextColor: "#ffffff",
      },
    },
    CTASection: {
      section: "CTA Section",
      content: {
        headingText: "Request the money you need",
        paraText:
          "Submit your information from the comfort of your home and get funds as soon as next business day.",
        CTAbuttonText: "Get Started",
      },
      style: {
        bgColor: "#15549a",
        headingColor: "#fff",
        ctaBg: "#2fc390",
        ctaTextColor: "#fff",
        ctaHoverBg: "#9cc983",
        ctaHoverTextColor: "#ffffff",
      },
    },

    footer: {
      section: "Footer",
      content: {
        footerLogo: "logo.png",
        sslLogo: "ssl.png",
        safe: "secure.png",
        incr: "encrypt.png",
        ola: "ola.png",
        bottomParaText:
          "THE OPERATOR OF THIS WEBSITE IS NOT A LENDER, is not a loan broker, and does not make lending decisions on behalf of lenders. This Web Site does not constitute an offer or solicitation to lend. This site will submit the information you provide to a lender who makes short-term cash loans to borrowers who meet its lending criteria. Providing your information on this Website does not guarantee that you will be approved for a short term cash loan. The operator of this Web Site is not an agent, representative or broker of any lender and does not endorse any particular lender or charge you for any service or product. Not all lenders can provide the maximum amount advertised. Cash transfer times may vary between lenders and may depend on your individual financial institution. In some circumstances faxing may be required. This service is not available in all states, and the states serviced by this Web Site may change from time to time and without notice. For details, questions or concerns regarding your short-term cash loan, please contact your lender directly. Short term cash loans are meant to address immediate cash needs and are not a long-term solution for financial problems. Residents of some states may not be eligible for a short term cash loan based upon lender requirements.",
        footerCopyrightText: "© All Rights Reserved.",
      },
      style: {
        bgColor: "#f2f2f2",
        linkColor: "#292929",
        linkHoverColor: "#2fc390",
        bottomParaTextColor: "#292929",
        footerCopyrightTextColor: "#0A346D",
      },
    },
  },
};

export const HomeContext = createContext();
export const PageContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [faviconUrl, setFaviconUrl] = useState("");
  const [homeData, setHomeData] = useState(defaultData);
  const [pageData, setPageData] = useState();

  useEffect(() => {
    fetch("data/default_data.json")
      .then((res) => res.json())
      .then((data) => {
        setHomeData(data);
      });
  }, []);

  React.useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        const myData = {
          websiteName: data.WEBSITE_NAME,
          webSiteId: data.WEBSITE_ID,
        };
        dataFetchHandler(myData);
        handleApiCall(myData);
      });
  }, []);

  // Data Fetch Hendler

  const dataFetchHandler = async (myData) => {
    setIsLoading(true);
    try {
      const res = await API.post("API/getLandingPageContent", myData);
      if (res.status === 200) {
        setHomeData(res.data.data);
        handleAppTitle(res.data.data.customization.title);
        setIsLoading(false);
        TestGA(res.data.data.ga_script);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const handleApiCall = async (myData) => {
    try {
      //  const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", myData);

      setPageData(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  // fav
  const Favicon = homeData?.favicon_img;
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = faviconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
    changeFavicon();
  }, [faviconUrl]);

  // Change the favicon dynamically
  const changeFavicon = () => {
    setFaviconUrl(`static/assets/images/${Favicon}`);
  };

  const handleAppTitle = (codeTitlr) => {
    document.title = `${codeTitlr}`;
  };

  // Push notifications
  const pushScriptUrl = homeData?.push_script;
  useScript(pushScriptUrl);

  const TestGA = (codeGa) => {
    TagManager.initialize({ gtmId: `${codeGa}` });
  };

  return !isLoading ? (
    <>
      <HomeContext.Provider value={homeData}>
        <PageContext.Provider value={pageData}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form" element={<MainForm />} />
          </Routes>
        </PageContext.Provider>
      </HomeContext.Provider>
    </>
  ) : (
    <div
      id="spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "150px",
      }}
    >
      <div>{/* <img src={favimg} className="" alt="favicon"/> */}</div>
      <div className="dots">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
      </div>
    </div>
  );
}

export default App;
