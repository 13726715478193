import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../App";

const CreditScoreSection = () => {
  const data = useContext(HomeContext);
  const creditScoreSectionContent =
    data?.customization?.creditScoreSection?.content;
  const creditScoreSectionSty = data?.customization?.creditScoreSection?.style;
  const creditScoreSectionImg = data.section2_img;
  var splitag = creditScoreSectionImg.split(",");
  var csImg1 = splitag[0];
  var csImg2 = splitag[1];
  var csImg3 = splitag[2];

  function CSStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .get_started{
      background: ${creditScoreSectionSty?.ctaBg};
      color: ${creditScoreSectionSty?.ctaTextColor};
      box-shadow: 0 0 5px 0 ${creditScoreSectionSty?.ctaShadow};
    }
    .get_started:hover{
      background: ${creditScoreSectionSty?.ctaHoverBg};
      box-shadow: 0 0 30px 0 ${creditScoreSectionSty?.ctaHoverShadow};
      color: ${creditScoreSectionSty?.ctaHoverTextColor};
    }
    `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    CSStyle();
  }, [creditScoreSectionSty]);

  return (
    <>
      <div className="cs_wrapper">
        <div className="container">
          <h2 style={{ color: creditScoreSectionSty?.mainHeadingColor }}>
            {creditScoreSectionContent?.titleText}
          </h2>
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_points">
                <div className="cs_item">
                  <img
                    src={`static/assets/images/${csImg1 ? csImg1 : "cs_1.png"}`}
                    alt=""
                  />
                  <h4
                    style={{ color: creditScoreSectionSty?.headertextColor }}
                    dangerouslySetInnerHTML={{
                      __html: creditScoreSectionContent?.icon1bold,
                    }}
                  />
                </div>
                <div className="cs_item">
                  <img
                    src={`static/assets/images/${csImg2 ? csImg2 : "cs_2.png"}`}
                    alt=""
                  />
                  `
                  <h4
                    style={{ color: creditScoreSectionSty?.headertextColor }}
                    dangerouslySetInnerHTML={{
                      __html: creditScoreSectionContent?.icon2bold,
                    }}
                  />
                </div>
                <div className="cs_item">
                  <img
                    src={`static/assets/images/${csImg3 ? csImg3 : "cs_3.png"}`}
                    alt=""
                  />
                  <h4
                    style={{ color: creditScoreSectionSty?.headertextColor }}
                    dangerouslySetInnerHTML={{
                      __html: creditScoreSectionContent?.icon3bold,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="cs_points_content">
                <p style={{ color: creditScoreSectionSty?.mainPraraColor }}>
                  {creditScoreSectionContent?.mainPara}
                </p>
                <p
                  className="disclos"
                  style={{ color: creditScoreSectionSty?.disclosPara }}
                >
                  {creditScoreSectionContent?.disclosurePara}
                </p>

                <a className="get_started" href="#">
                  {creditScoreSectionContent?.CTAbuttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditScoreSection;
